import {Dispatch, SetStateAction, useCallback, useRef} from "react";
import mapboxgl, {MapboxGeoJSONFeature} from 'mapbox-gl';
import {useDispatch} from "react-redux";
import {clearedSelectedMapFeatures, setSelectedMapFeatures} from "../../redux/showInfo/showInfoReducer";
import {FeatureProperties} from "../../redux/map/types";
import type {MapRef} from "react-map-gl";
import cloneDeep from "lodash/cloneDeep";
import {getUniqueFeatures, removeFeatureState, setFeatureState} from "../../utils/mapUtils";


const useMapFeatureSelectionEventListeners = (setDragPan: Dispatch<SetStateAction<boolean>>) => {
    const highlightedFeaturesByIds = useRef<{
        [key: string]: MapboxGeoJSONFeature & { properties: FeatureProperties } //GeoJSON.Feature<GeoJSON.Geometry, FeatureProperties>
    }>({});
    const mapRef = useRef<MapRef>();
    const canvasRef = useRef<HTMLElement>();
    const dispatch = useDispatch();

    function onKeyUp(e) {
        if (e.keyCode === 27 && mapRef.current) {
            for (const feature of Object.values(highlightedFeaturesByIds.current)) {
                const {properties: {featureStateId, layerId}} = feature;
                delete highlightedFeaturesByIds.current[featureStateId];
                removeFeatureState(mapRef.current, feature, 'highlighted');
            }

            dispatch(clearedSelectedMapFeatures());
        }
    }

    function dispatchSelectedFeatures(features: (MapboxGeoJSONFeature & {
        properties: FeatureProperties
    })[]) {
        dispatch(setSelectedMapFeatures({
            selectedFeatures: features.map(({
                                                id,
                                                type,
                                                layer,
                                                source,
                                                properties,
                                                geometry
                                            }) => ({
                id,
                type,
                source,
                properties,
                geometry
            })) as unknown as MapboxGeoJSONFeature[],
        }));
    }

    function addOrRemoveFeaturesState(mapRef: MapRef, features: (MapboxGeoJSONFeature & {
        properties: FeatureProperties
    })[] | undefined, stateProperty: string) {
        for (const feature of features ?? []) {
            const {source, properties: {featureStateId}, state} = feature;

            if (state[stateProperty]) {
                delete highlightedFeaturesByIds.current[featureStateId];
                removeFeatureState(mapRef, feature, stateProperty);
            } else {
                highlightedFeaturesByIds.current[featureStateId] = cloneDeep(feature);
                setFeatureState(mapRef, feature, stateProperty, true);
            }
        }

        dispatchSelectedFeatures(Object.values(highlightedFeaturesByIds.current));
    }

    function removeAllFeaturesState(mapRef: MapRef, stateProperty: string) {
        const highlightedFeatures = Object.values(highlightedFeaturesByIds.current);

        for (const feature of highlightedFeatures) {
            removeFeatureState(mapRef, feature, stateProperty);
        }

        highlightedFeaturesByIds.current = {};
        dispatch(clearedSelectedMapFeatures());
    }

    const featureClickListener = useCallback((event: mapboxgl.MapLayerMouseEvent) => {
        const {features, point, lngLat, originalEvent} = event;
        const {shiftKey} = originalEvent;

        const uniqueFeatures = getUniqueFeatures(features ?? []) as (MapboxGeoJSONFeature & { properties: FeatureProperties })[];

        if (mapRef.current) {
            if (!shiftKey && uniqueFeatures?.length === 1) {
                removeAllFeaturesState(mapRef.current, 'highlighted');
            }
            addOrRemoveFeaturesState(mapRef.current, uniqueFeatures, 'highlighted');
        }
    }, [])

    const addFeatureSelectionEventListeners = (map: MapRef) => {
        mapRef.current = map;
        canvasRef.current = map.getCanvasContainer();
        canvasRef.current.addEventListener('mousedown', mouseDown, true);
        document.addEventListener('keyup', onKeyUp);
    }

    let start;
    let current;
    let box;

    function mousePos(e) {
        if (canvasRef.current) {
            const rect = canvasRef.current.getBoundingClientRect();
            return new mapboxgl.Point(
                e.clientX - rect.left - canvasRef.current.clientLeft,
                e.clientY - rect.top - canvasRef.current.clientTop
            );
        }
    }

    function mouseDown(e) {
        if (!(e.shiftKey && e.button === 0)) {
            return;
        }

        setDragPan(false);

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);

        start = mousePos(e);
    }

    function onMouseMove(e) {
        current = mousePos(e);

        if (!box && canvasRef.current) {
            box = document.createElement('div');
            box.classList.add('boxdraw');
            canvasRef.current.appendChild(box);
        }

        const minX = Math.min(start.x, current.x),
            maxX = Math.max(start.x, current.x),
            minY = Math.min(start.y, current.y),
            maxY = Math.max(start.y, current.y);

        const pos = `translate(${minX}px, ${minY}px)`;
        box.style.transform = pos;
        box.style.width = maxX - minX + 'px';
        box.style.height = maxY - minY + 'px';
    }

    function onMouseUp(e) {
        finish([start, mousePos(e)]);
    }

    function finish(bbox) {
        if (bbox && mapRef.current) {
            const features = mapRef.current.queryRenderedFeatures(bbox,
                {
                    filter: ['!=', ['string', ['get', 'layerId']], 'composite']
                }
            );

            if (features.length >= 1000) {
                return window.alert('Select a smaller number of features');
            }

            addOrRemoveFeaturesState(mapRef.current, features as any, 'highlighted');
        }

        setDragPan(true);

        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);

        if (box) {
            box.parentNode.removeChild(box);
            box = null;
        }
    }

    const removeFeatureSelectionEventListeners = useCallback(() => {
        document.removeEventListener('keyup', onKeyUp);
    }, [onKeyUp])

    return {addFeatureSelectionEventListeners, featureClickListener, removeFeatureSelectionEventListeners};
}

export default useMapFeatureSelectionEventListeners;