import {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import mapApi from "../../api/mapApi";
import {isErrorResponse} from "../../utils/utils";
import {getUniqueFeatures, parseFeature} from "../../utils/mapUtils";
import mapboxgl from "mapbox-gl";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectRedirectOnFeatureSelection} from "../../redux/selectors/selectors";
import {GeoJsonGeometryTypes} from "geojson";


type Props = {
    getUrlFunc?: ({parentId, childId}: {
        parentId?: string;
        childId: string;
        featureType: GeoJsonGeometryTypes,
        entityName: string
    }) => void;
    findParent?: boolean;
};

type ReturnType = {
    navigateOnFeatureSelection: (event: mapboxgl.MapLayerMouseEvent, isDrawing?: boolean) => void;
}

export const useNavigationOnFeaturesSelection = ({getUrlFunc, findParent = false}: Props = {}): ReturnType => {
    const navigate = useNavigate();
    const redirectOnFeatureSelection = useTypedSelector(selectRedirectOnFeatureSelection);

    //TODO pass URL path as arguments => remove hardcode
    const navigateOnFeatureSelection = useCallback(async (event: mapboxgl.MapLayerMouseEvent, isDrawMode?: boolean) => {
        if (isDrawMode || !redirectOnFeatureSelection) {
            return;
        }

        const {features, originalEvent: {shiftKey}} = event;

        const uniqueFeatures = getUniqueFeatures(features ?? []);

        const shouldRedirectToEntitiesList = !shiftKey && !uniqueFeatures?.length;

        //TODO is it OK that the whole app rerenders after redirect?
        if (shouldRedirectToEntitiesList) {
            navigate(`./`);
            return;
        }

        const shouldRedirectToEntityEditor = uniqueFeatures?.length === 1 && !shiftKey;

        if (shouldRedirectToEntityEditor) {
            const abortController = new AbortController();

            const feature = uniqueFeatures[0];
            const {id, type, entityName} = parseFeature(feature);
            const resp = await mapApi.getFeatureById(id, entityName, abortController.signal, findParent);

            if (!isErrorResponse(resp)) {
                navigate(getUrlFunc?.({
                    parentId: resp.id as string,
                    childId: id,
                    featureType: type,
                    entityName
                }) ?? `./${id}/edit`);
            }
        }
    }, [redirectOnFeatureSelection]);

    return {navigateOnFeatureSelection};
}